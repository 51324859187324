<template>
  <div>
    <section class="product-list-heading mb-0 box-my-account">
      <div class="container">
        <div class="row promotion-box justify-content-center">
          <div class="col-12">
            <h1 class="mt-4 text-center border-bottom30">Promotion</h1>
          </div>
          <div class="col-md-4 p-3" v-for="(promo, i) in promos" :key="i">
            <div class="card">
              <div class="d-flex align-items-center border-bottom">
                <img
                  class="w-100 m-auto"
                  :src="promo.image_responsive_src"
                  :alt="promo.image_description"
                  @error="defaultBanner"
                />
              </div>
              <div class="d-flex px-2 align-items-center">
                <h4 class="text-left">{{ promo.name }}</h4>
                <div
                  style="cursor: pointer"
                  class="
                    rounded
                    d-inline-block
                    px-2
                    py-1
                    ml-auto
                    terms
                    bg-terms
                    text-center
                  "
                  v-if="promo.terms_condition"
                  v-b-modal.modal-term
                  @click="terms = promo.terms_condition"
                >
                  Terms &amp; Conditions
                </div>
              </div>
              <div class="row px-3">
                <div class="col pl-2 pr-1 d-flex">
                  <div
                    class="d-inline mt-1"
                    :style="{
                      background:
                        'url(' +
                        require('@/assets/img/CALENDAR-ICON.png') +
                        ')',
                      'background-size': '24px',
                      'background-repeat': 'no-repeat',
                      width: '30px',
                      height: '24px',
                    }"
                  ></div>
                  <div class="d-inline w-100 pl-1">
                    <strong>Promotion Period</strong>
                    <p class="m-0">
                      {{ formatDate(promo.active_from) }} -
                      {{ formatDate(promo.active_to) }}
                    </p>
                  </div>
                </div>
                <div v-if="promo.promo_offer" class="col pl-1 pr-2 d-flex">
                  <div
                    class="d-inline mt-1"
                    :style="{
                      background:
                        'url(' + require('@/assets/img/PROMO-ICON.png') + ')',
                      'background-size': '24px',
                      'background-repeat': 'no-repeat',
                      width: '30px',
                      height: '24px',
                    }"
                  ></div>
                  <div class="d-inline w-100 pl-2">
                    <strong>Offer</strong>
                    <p class="m-0">
                      {{ promo.promo_offer }}
                    </p>
                  </div>
                </div>
              </div>
              <router-link
                :to="'/products/' + promo.slug"
                @click.native="firebaseSelectPromotion(promo)"
                class="btn btn-wz-proceed rounded m-2"
              >
                CHECK PROMO!
              </router-link>
            </div>
          </div>
        </div>
      </div>
    </section>
    <ModalTermsOfService v-if="terms" :terms="terms" />
  </div>
</template>

<script>
import ModalTermsOfService from "@/components/modals/ModalTermsOfService.vue";
import moment from "moment";

export default {
  name: "PromoPage",
  components: {
    ModalTermsOfService,
  },
  data() {
    return {
      terms: null
    }
  },
  computed: {
    promos() {
      return this.$store.getters.getPromoList;
    }
  },
  methods: {
    firebaseSelectPromotion(data) {
      // when user click on a promotion banner
      this.$firebaseLogEvent("select_promotion", {
        promotion_name: data.name,
      });
    },
    formatDate(value) {
      return moment(String(value)).format("DD/MM/YY");
    },
    defaultBanner(img) {
      img.target.src = require("@/assets/img/banner-01.jpg");
    },
  },
  watch: {
    promos(val) {
      if (!val) return
      setTimeout(() => {
        window.prerenderReady = true;
      }, 2000)
    }
  }
};
</script>

