var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('section',{staticClass:"product-list-heading mb-0 box-my-account"},[_c('div',{staticClass:"container"},[_c('div',{staticClass:"row promotion-box justify-content-center"},[_vm._m(0),_vm._l((_vm.promos),function(promo,i){return _c('div',{key:i,staticClass:"col-md-4 p-3"},[_c('div',{staticClass:"card"},[_c('div',{staticClass:"d-flex align-items-center border-bottom"},[_c('img',{staticClass:"w-100 m-auto",attrs:{"src":promo.image_responsive_src,"alt":promo.image_description},on:{"error":_vm.defaultBanner}})]),_c('div',{staticClass:"d-flex px-2 align-items-center"},[_c('h4',{staticClass:"text-left"},[_vm._v(_vm._s(promo.name))]),(promo.terms_condition)?_c('div',{directives:[{name:"b-modal",rawName:"v-b-modal.modal-term",modifiers:{"modal-term":true}}],staticClass:"rounded d-inline-block px-2 py-1 ml-auto terms bg-terms text-center",staticStyle:{"cursor":"pointer"},on:{"click":function($event){_vm.terms = promo.terms_condition}}},[_vm._v(" Terms & Conditions ")]):_vm._e()]),_c('div',{staticClass:"row px-3"},[_c('div',{staticClass:"col pl-2 pr-1 d-flex"},[_c('div',{staticClass:"d-inline mt-1",style:({
                    background:
                      'url(' +
                      require('@/assets/img/CALENDAR-ICON.png') +
                      ')',
                    'background-size': '24px',
                    'background-repeat': 'no-repeat',
                    width: '30px',
                    height: '24px',
                  })}),_c('div',{staticClass:"d-inline w-100 pl-1"},[_c('strong',[_vm._v("Promotion Period")]),_c('p',{staticClass:"m-0"},[_vm._v(" "+_vm._s(_vm.formatDate(promo.active_from))+" - "+_vm._s(_vm.formatDate(promo.active_to))+" ")])])]),(promo.promo_offer)?_c('div',{staticClass:"col pl-1 pr-2 d-flex"},[_c('div',{staticClass:"d-inline mt-1",style:({
                    background:
                      'url(' + require('@/assets/img/PROMO-ICON.png') + ')',
                    'background-size': '24px',
                    'background-repeat': 'no-repeat',
                    width: '30px',
                    height: '24px',
                  })}),_c('div',{staticClass:"d-inline w-100 pl-2"},[_c('strong',[_vm._v("Offer")]),_c('p',{staticClass:"m-0"},[_vm._v(" "+_vm._s(promo.promo_offer)+" ")])])]):_vm._e()]),_c('router-link',{staticClass:"btn btn-wz-proceed rounded m-2",attrs:{"to":'/products/' + promo.slug},nativeOn:{"click":function($event){return _vm.firebaseSelectPromotion(promo)}}},[_vm._v(" CHECK PROMO! ")])],1)])})],2)])]),(_vm.terms)?_c('ModalTermsOfService',{attrs:{"terms":_vm.terms}}):_vm._e()],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-12"},[_c('h1',{staticClass:"mt-4 text-center border-bottom30"},[_vm._v("Promotion")])])
}]

export { render, staticRenderFns }